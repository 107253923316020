.site-client{
  padding: 100px 0 50px;
  .section-title{
    margin-bottom: 100px;
  }
  &-wrapper{
    display: flex;
    flex-wrap: wrap;
    @include desktop{
      justify-content: center;
    }
  }
  &-item{
    text-align: center;
    width: 20%;
    margin-bottom: 50px;
    @include desktop{
      width: 33.33%;
    }
    @include mobile{
      width: 50%;
    }
  }
}