// html{
//   scroll-behavior: smooth;
// }

body {
  background: $body-color;
}

.section-title{
  margin-bottom: 70px;
  @include desktop{
    text-align: center;
  }
  h2{
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
    @include desktop{
      font-size: 40px;
    }
  }
  p{
    font-size: 20px;
    font-weight: 300;
  }
}

.form-control{
  background: transparent;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 0 0 10px 0;
  border-width: 0 0 1px 0;
  border-color: $primary-color;
  font-weight: 300;
  letter-spacing: 1px;
  -webkit-appearance: none;
  height: 42px;
  &:focus{
    box-shadow: none;
    background: transparent;
    border-color: $text-color;
  }
}

select.form-control{
  height: 42px !important;
  position: relative;
}

.select-wrapper{
  position: relative;
  &::before{
    content: '';
    position: absolute;
    width: 16px;
    height: 10px;
    background: url('../images/angle-down-select.svg');
    background-size: contain;
    background-repeat: no-repeat;
    right: 15px;
    top: 50%;
    transform: translateY(-80%);
  }
}

textarea.form-control{
  height: auto;
}
