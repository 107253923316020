.site-about{
  &-header{
    padding-top: 80px;
    background-color: $gray;
    @include mobile{
      padding-top: 60px;
      text-align: center;
    }
  }
  &-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-company{
    background: $primary-color;
    width: 40%;
    padding: 70px 38px;
    @include desktop{
      width: 100%;
      margin-bottom: 20px;
    }
    h1{
      color: $white;
      font-weight: 400;
      font-size: 25px;
      line-height: 60px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  &-description{
    width: 60%;
    padding-left: 50px;
    @include desktop{
      width: 100%;
      padding: 0 38px;
    }
    p{
      font-size: 25px;
      font-weight: 300;
      margin-bottom: 0;
    }
  }
}