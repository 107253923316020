.site-footer{
  background: $secondary-color;
  padding: 100px 0;
  &-logo{
    margin-bottom: 50px;
    @include mobile{
      text-align: center;
    }
  }
  &-widget{
    @include desktop{
      margin-bottom: 40px;
    }
    @include mobile{
      text-align: center;
    }
    &-title{
      color: $white;
      margin-bottom: 30px;
      @include desktop{
        margin-bottom: 20px;
      }
    }
    &-description{
      color: $white;
      line-height: 30px;
      a{
        color: $white;
      }
    }
    &-links{
      margin: 0;
      padding: 0;
      li{
        list-style: none;
        a{
          color: $white;
          transition: all 0.3s $site-ease;
          display: block;
        }
        &:not(:last-child){
          a{
            padding-bottom: 9px;
          }
        }
      }
    }
    &-top{
      @include mobile{
        text-align: center;
        display: block;
      }
      img{
        margin-bottom: 20px;
      }
      p{
        color: $white;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
}