.site-project{
  padding: 120px 0;
  overflow: hidden;
  &-item{
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    &-thumb{
      img{
        width: 100%;
      }
    }
    &-content{
      position: absolute;
      background: $gray;
      width: 100%;
      padding: 45px 50px;
      bottom: 0;
      transition: all .3s $site-ease;
      @include tablet{
        padding: 30px;
        padding-bottom: 75px;
      }
      span{
        font-family: $secondary-font;
        color: $primary-color;
        display: block;
        margin-bottom: 10px;
      }
      h3{
        margin-bottom: 0;
        a{
          color: $text-color-dark;
          transition: 0.3s ease;
          &:hover{
            text-decoration: none;
            color: $primary-color;
          }
        }
      }
      a.read-more{
        position: absolute;
        bottom: 40px;
        display: block;
        width: 100%;
        color: $text-color;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s $site-ease;
        @include tablet{
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    &:hover{
      box-shadow: 
      0px 30px 45px 0px rgba(0, 0, 0, 0.01),
      0px 30px 45px 0px rgba(0, 0, 0, 0.02),
      0px 30px 45px 0px rgba(0, 0, 0, 0.03),
      0px 30px 45px 0px rgba(0, 0, 0, 0.04);
      .site-project-item-content{
        a{
          transform: translateY(0);
          opacity: 1;
        }
        padding-bottom: 75px;
      }
    }
  }
  &-cta{
    margin-top: 30px;
    display: inline-block;
    position: relative;
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 500;
    color: $primary-color;
    &::before{
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background: $primary-color;
      bottom: -2px;
      transition: transform 0.3s $site-ease;
      transform-origin: right;
      transform: scaleX(0);
    }
    &:hover{
      color: $primary-color;
      text-decoration: none;
      &::before{
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
  &-header{
    padding: 170px 0 100px;
    background: $gray;
    @include tablet{
      padding: 120px 0 80px;
    }
    &-content{
      h1{
        font-size: 55px;
        span{
          color: $primary-color;
        }
        @include tablet{
          font-size: 40px;
        }
      }
      @include desktop{
        text-align: center;
        margin-bottom: 30px;
      }
    }
    &-action{
      display: block;
      text-align: center;
      span{
        display: block;
        font-size: 30px;
        font-weight: 300;
        color: $text-color-dark;
        margin-bottom: 20px;
        @include tablet{
          font-size: 25px;
        }
      }
      img{
        width: 66px;
        @include tablet{
          width: 40px;
        }
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
  &-pagination{
    margin-top: 40px;
    .pagination{
      justify-content: center;
      .page-item{
        &:not(:last-child){
          margin-right: 10px;
        }
        .page-link{
          font-size: 24px;
          border-radius: 0;
          border: none;
          padding: 12px 20px;
          color: $text-color-dark;
          font-weight: 300;
          transition: all 0.3s ease;
        }
        &.active{
          .page-link{
            background-color: $primary-color;
            color: $white;
          }
        }
        &.disabled{
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }
  }
  &-single{
    &-section{
      padding: 250px 0 100px;
      @include tablet{
        padding: 100px 0;
      }
    }
    h1{
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 40px;
    }
    &-image{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      img{
        width: 48%;
        &:nth-child(2n){
          margin-left: auto;
        }
        @include tablet{
          width: 100%;
        }
      }
    }
    &-description{
      margin-bottom: 50px;
      p{
        margin-bottom: 30px;
      }
    }
    &-action{
      a{
        position: relative;
        font-size: 24px;
        font-weight: 300;
        color: $text-color-dark;
        display: flex;
        margin-left: auto;
        justify-content: flex-end;
        @include mobile{
          transform: translateX(-10px);
        }
        .link-area{
          display: inline-block;
          overflow: hidden;
          span{
            position: relative;
            display: block;
            padding: 2px 0;
            transition: transform .9s cubic-bezier(.19,1,.22,1);
            transform-origin: left center;
            &::after{
              content: attr(data-text);
              position: absolute;
              top: 150%;
              left: 0;
              transform: skewY(30deg);
              transform-origin: left center;
              transition: transform .9s cubic-bezier(.19,1,.22,1);
            }
          }
        }
        &:hover{
          text-decoration: none;
          .link-area{
            span{
              transform: translateY(-150%) skewY(7deg);
              &::after{
                transform: skewY(-7deg);
              }
            }
          }
        }
      }
      img{
        filter: invert(1);
        transform: rotate(90deg);
        margin-left: 30px;
      }
    }
  }
}