.site-navigation{
  padding: 20px 0;
  background: $white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  @include desktop{
    background: $white;
    margin: 10px;
    border-radius: 5px;
    top: 0;
    width: calc(100% - 20px);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.09);
  }
  @include mobile{
    padding: 15px;
  }
  .navbar-brand{
    padding: 0;
    margin: 0;
    img{
      max-width: 78px;
    }
  }
  .nav-item{
    @include desktop{
      text-align: center;
    }
    .nav-link{
      font-family: $secondary-font;
      color: $text-color-dark;
      transition: all .3s ease;
      padding: 15px 18px;
      position: relative;
    }
    &:last-child{
      margin-left: 15px;
    }
  }
  .navbar-toggler{
    outline: 0;
    padding: 0;
    .icon-bar {
      height: 2px;
      width: 25px;
      transition: all 0.2s;
      background: $primary-color;
      display: block;
      &:not(:last-child){
        margin-bottom: 5px;
      }
      &:nth-child(1){
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      &:nth-child(2){
        opacity: 0;
	      filter: alpha(opacity=0);
      }
      &:nth-child(3){
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }
    &.collapsed{
      .icon-bar{
        &:nth-child(1){
          transform: rotate(0);
        }
        &:nth-child(2){
          opacity: 1;
          filter: alpha(opacity=1);
        }
        &:nth-child(3){
          transform: rotate(0);
        }
      }
    }
  }
  &.nav-bg{
    padding: 12px 0;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.09);
    @include mobile{
      padding: 15px;
    }
  }
}