.site-testimonial{
  background: $gray;
  padding: 100px 0;
  overflow: hidden;
  &-item{
    background: $white;
    padding: 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    &.active{
      box-shadow: 0px 30px 45px 0px rgba(0, 0, 0, 0.05);
    }
    &.inactive{
      opacity: 0.4;
    }
    &-header{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .thumb{
        width: 80px;
        img{
          width: 100%;
        }
        margin-right: 15px;
      }
      .person{
        h5{
          margin-bottom: 5px;
        }
        p{
          margin-bottom: 0;
          color: $dark-gray;
        }
      }
    }
    &-body{
      margin-bottom: 0;
      font-weight: 300;
    }
  }
}