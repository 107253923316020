.btn{
  font-family: $secondary-font;
  border: none;
  color: $white !important;
  padding: 20px 45px;
  border-radius: 0;
  &:focus{
    box-shadow: none;
  }
  @include desktop{
    padding: 17px 40px;
  }
  .btn-area{
    display: block;
    overflow: hidden;
    span{
      position: relative;
      display: block;
      padding: 2px 0;
      transition: transform .9s cubic-bezier(.19,1,.22,1);
      transform-origin: left center;
      &::after{
        content: attr(data-text);
        position: absolute;
        top: 150%;
        left: 0;
        transform: skewY(30deg);
        transform-origin: left center;
        transition: transform .9s cubic-bezier(.19,1,.22,1);
      }
    }
  }
  &:hover{
    .btn-area{
      span{
        transform: translateY(-150%) skewY(7deg);
        &::after{
          transform: skewY(-7deg);
        }
      }
    }
  }
  &:visited{
    .btn-area{
      span{
        transform: translateY(-150%) skewY(7deg);
        &::after{
          transform: skewY(-7deg);
        }
      }
    }
  }
}

.btn-sm{
  padding: 15px 40px !important;
}

.btn-sm-rounded{
  border-radius: 40px;
}

.btn-primary{
  background: $primary-color;
  color: $white;
  &:hover{
    background: darken($color: $primary-color, $amount: 10);
  }
}

.btn-secondary{
  background: $secondary-color;
  color: $white;
  &:hover{
    background: darken($color: $secondary-color, $amount: 10);
  }
}