.site-team{
  padding: 100px 0;
  &-member{
    background: $gray;
    padding: 65px 30px 30px;
    margin: 50px 0 30px;
    position: relative;
    @include tablet{
      text-align: center;
    }
    &-image{
      position: absolute;
      width: 100px; height: 100px;
      border-radius: 50%;
      overflow: hidden;
      left: 30px; top: 0;
      transform: translateY(-50%);
      @include tablet{
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-content{
      h3{
        font-size: 20px;
        color: $primary-color;
        margin-bottom: 10px;
        font-weight: 500;
      }
      p{
        font-size: 18px;
        color: $text-color-dark;
        margin-bottom: 20px;
        font-weight: 300;
      }
    }
    &-social{
      margin: 0;
      padding: 0;
      li{
        display: inline-block;
        a{
          color: $text-color-dark;
        }
        &:not(:last-child){
          margin-right: 10px;
        }
      }
    }
  }
}