.site-hero{
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop{
    height: auto;
    padding: 200px 0;
  }
  @include mobile{
    padding: 150px 0;
  }
  &-content{
    h1{
      margin-bottom: 75px;
      @include desktop-lg{
        font-size: 50px;
        margin-bottom: 50px;
      }
      @include mobile{
        font-size: 40px;
      }
    }
    &-buttons{
      margin: 0;
      padding: 0;
      li{
        display: inline-block;
        @include mobile{
          display: block;
        }
        &:not(:last-child){
          margin-right: 30px;
          @include mobile{
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        .btn{
          font-weight: 600;
          @include mobile{
            width: 90%;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }
  &-scroll{
    position: absolute;
    left: 50%; bottom: 70px;
    transform: translateX(-50%);
  }
}