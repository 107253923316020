.site{
  &-blog{
    @extend .site-project;
    @include mobile{
      padding: 70px 0;
    }
    &.details{
      padding: 150px 0 80px;
      @include tablet{
        padding: 100px 0 60px;
      }
    }
    &-header{
      padding: 150px 0 0;
      @include mobile{
        padding: 100px 0 0;
      }
      h1{
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 10px;
        margin-bottom: 20px;
        @include mobile{
          font-size: 35px;
        }
      }
      p{
        line-height: 28px;
        margin-bottom: 0;
      }
    }
    &-pagination{
      @extend .site-project-pagination;
    }
    &-post{
      @extend .site-project-item;
      &-thumb{
        @extend .site-project-item-thumb;
      }
      &-content{
        @extend .site-project-item-content;
        @include mobile{
          padding: 15px 25px 40px;
        }
        span{
          font-size: 14px;
        }
        h3{
          margin-bottom: 15px;
          @include mobile{
            font-size: 20px;
          }
        }
        a.read-more{
          color: $primary-color;
          letter-spacing: 2px;
          @include mobile{
            transform: translateY(22px);
          }
        }
      }
    }
    &-details{
      img{
        max-width: 100%;
        margin-bottom: 20px;
      }
      .blog-title{
        font-size: 40px;
      }
      .feature-image{
        margin: 20px 0 30px;
      }
      h2,h3,h4,h5,h6{
        margin-bottom: 15px;
      }
      blockquote{
        p{
          color: $text-color-dark;
          font-size: 18px;
        }
      }
      cite{
        margin-top: 10px;
        display: block;
      }
    }
  }
}